<template>
  <!--begin::Tables Widget 13-->
  <div class="card row" :style="widgetClasses" style="border-radius: 0.5px">
    <!--begin::Header-->
    <CustomTableTopFilter
      :filterStatus="filterStatus"
      :pageProp="pageProp"
      :tableName="tableName"
      :pages="pages"
      :showFilters="showFilters"
      :showDatesFilters="false"
      @paginatorHandler="paginatorHandler"
      @updateStatus="updateStatus"
      @queryForOccurences="queryForProduct"
    ></CustomTableTopFilter>

    <!--end::Header-->

    <!--begin::Body-->
    <div class="card-body mb-5 py-3 px-0">
      <!--begin::Table container-->
      <div
        class="overflow-auto col"
        style="overflow-x: scroll !important; max-width: 100%"
      >
        <!--begin::Table-->
        <table class="table table-row-gray-500 gy-3 gs-5 mb-0">
          <!--begin::Table head-->
          <thead id="table-head">
            <tr class="fw-bolder text-muted" style="white-space: nowrap">
              <th class="text-dark fs-7 fw-bolder">
                <span v-if="loading">
                  <i class="fas fa-spinner fa-spin text-dark"></i
                  >&nbsp;Código do Artigo</span
                >
                <span
                  v-if="!loading"
                  @click="sortBy('strCodigo')"
                  :class="{
                    /* 'bi-sort-numeric-up-alt': orderAsc,
                    'bi-sort-numeric-down-alt': !orderAsc, */
                    'bi-sort-alpha-up':
                      showOrderByIcon == 'strCodigo' && !orderAsc,
                    'bi-sort-alpha-down':
                      showOrderByIcon == 'strCodigo' && orderAsc,
                    'cursor-pointer': true,
                  }"
                  >&nbsp;Código do Artigo</span
                >
              </th>
              <th class="text-dark fs-7 fw-bolder">Abreviatura</th>
              <th class="text-dark fs-7 fw-bolder">
                <span v-if="loading">
                  <i class="fas fa-spinner fa-spin text-dark"></i>&nbsp;Data de
                  compra</span
                >
                <span
                  v-if="!loading"
                  @click="sortBy('dtmCompra')"
                  :class="{
                    /* 'bi-sort-numeric-up-alt': orderAsc,
                    'bi-sort-numeric-down-alt': !orderAsc, */
                    'bi-sort-alpha-up':
                      showOrderByIcon == 'dtmCompra' && !orderAsc,
                    'bi-sort-alpha-down':
                      showOrderByIcon == 'dtmCompra' && orderAsc,
                    'cursor-pointer': true,
                  }"
                  >&nbsp;Data de compra</span
                >
              </th>
              <th class="text-dark fs-7 fw-bolder">Descrição</th>
              <th class="text-dark fs-7 fw-bolder text-center">Tipo Stock</th>
              <th class="text-dark fs-7 fw-bolder text-center">Imagem</th>
              <th class="text-dark fs-7 fw-bolder text-center">
                Ficha Técnica
              </th>
              <th class="col-4 col-xxl-2 text-dark fs-7 fw-bolder">
                <div class="d-flex justify-content-between me-2">
                  <span style="min-width: 8rem">Quantidade</span
                  ><span
                    title="Adicionar"
                    class="text-center mx-1"
                    style="width: 2.2rem"
                    ><section>
                      <xlsx-workbook>
                        <xlsx-sheet
                          :collection="sheet.data"
                          v-for="sheet in sheetExample"
                          :key="sheet.name"
                          :sheet-name="sheet.name"
                        />
                        <xlsx-download :filename="`Exportar_produtos.xlsx`">
                          <button
                            class="mx-1 fs-7"
                            style="
                              border: none;
                              background-color: transparent;
                              width: 2.4rem;
                            "
                            title="Descarregar para excel"
                          >
                            <i
                              v-if="!isCreatingExcel"
                              class="fas fa-download text-dark fs-7"
                            ></i>
                            <span
                              v-if="isCreatingExcel"
                              class="fas fa-spinner fa-spin align-middle mb-1 size-xxs text-dark"
                            ></span>
                            <span
                              class="svg-icon svg-icon-3 svg-icon-dark custom-span"
                              style="color: dark"
                            >
                            </span>
                          </button>
                        </xlsx-download>
                      </xlsx-workbook></section
                  ></span>
                </div>
              </th>
            </tr>
          </thead>
          <!--end::Table head-->

          <!--begin::Table body-->
          <tbody>
            <template v-if="loading && list.length == 0"
              ><tr v-for="r in 15" :key="r" style="height: 38px"></tr
            ></template>
            <template v-for="(item, index) in list" :key="index">
              <tr class="border-bot" style="vertical-align: middle">
                <td class="text-dark fw-bolder fs-7" style="">
                  {{ item.strCodigo }}
                </td>
                <td class="text-dark fs-7" style="">
                  {{ item.strAbreviatura }}
                </td>
                <td class="text-dark fs-7" style="">
                  {{ getFormatedDate(item.dtmCompra) }}
                </td>
                <td class="fs-7">
                  {{ item.strDescricao }}
                </td>
                <td class="text-center fs-7" style="">
                  {{ item.tipoStock }}
                </td>
                <!-- input field for quantity and + button -->
                <td class="fs-6 align-middle text-center">
                  <div class="text-center cursor-pointer">
                    <DisplayImage :item="item.strCodigo"></DisplayImage>
                  </div>
                </td>
                <td class="fs-6 align-middle text-center">
                  <button
                    v-if="showDownloadBtn && item.documento"
                    @click="downloadPdf(item.documento)"
                    class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm mx-1 my-1"
                    style="
                      background-color: white;
                      border: 1px solid #e4e6ef;
                      height: 2.5rem;
                      width: 2.5rem;
                    "
                    id="btn-pdf-download"
                    :download="item.documentName + item.documentNumber"
                    :href="item.invoicePdf"
                    title="Abrir/descarregar ficha técnica"
                  >
                    <i class="fas fa-file-pdf"></i>
                  </button>
                </td>
                <td class="pt-1 pb-1">
                  <div class="d-flex align-items-center">
                    <input
                      type="number"
                      class="form-control form-control-sm pt-2 pb-2 mt-1 m-1"
                      placeholder="Quantidade"
                      style="min-height: 2.4rem; min-width: 8rem"
                      min="1"
                      v-model="item.fltQuantidade"
                    />
                    <button
                      @click="addProduct(item)"
                      class="btn btn-icon btn-main-table btn-bg-light btn-active-color-primary btn-sm btn-add-qtt mt-1 m-1"
                      style="background-color: white; border: 1px solid #e4e6ef"
                      title="Adicionar ao carrinho"
                    >
                      <span class="svg-icon svg-icon-6">
                        <inline-svg
                          src="media/icons/duotune/csw_custom/arr009.svg"
                        />
                      </span>
                    </button>
                  </div>
                </td>
              </tr>
            </template>
          </tbody>
          <!--end::Table body-->
        </table>
        <!--end::Table-->
      </div>
      <!--end::Table container-->
    </div>
    <!--begin::Body-->

    <CustomTableBottomPagination
      :pageProp="pageProp"
      :pages="pages"
      :itemsPerPage="itemsPerPage"
      :totalItems="totalItems"
      @updateFetchPage="updateFetchPage"
      @paginatorHandler="paginatorHandler"
      @updateStatus="updateStatus"
    >
    </CustomTableBottomPagination>
    <!-- Loader -->
    <transition name="loader">
      <div v-if="loading" class="overlay-loader">
        <div class="text-center">
          <span
            class="spinner-border spinner-border-lg custom-loader align-middle my-2 text-primary"
            role="status"
          ></span
          ><br /><span class="text-white fs-4 fw-bold visually-hidden"
            >A carregar...</span
          >
        </div>
      </div>
    </transition>
    <!-- End Loader -->
  </div>
  <!--end::Tables Widget 13-->
</template>

<script>
import {
  XlsxRead,
  XlsxSheets,
  XlsxJson,
  XlsxWorkbook,
  XlsxSheet,
  XlsxDownload,
} from "/node_modules/vue3-xlsx/dist/vue3-xlsx.cjs.prod.js";
import { defineComponent } from "vue";
import Dropdown2 from "@/components/dropdown/Dropdown2.vue";
import CustomTableTopFilter from "@/components/customTables/CustomTableTopFilter.vue";
import CustomTableBottomPagination from "@/components/customTables/CustomTableBottomPagination.vue";
import { boolean } from "yup/lib/locale";
import ApiService from "@/core/services/ApiService";
import DisplayImage from "@/components/Orders/DisplayImage.vue";
import store from "@/store";

/* eslint-disable */
export default defineComponent({
  name: "CustomTableProducts",
  data() {
    return {
      rowsToShow: [""],
      orderAsc: true,
      showOrderByIcon: "dtmCompra",

      isCreatingExcel: true,
      file: null,
      selectedSheet: null,
      sheetName: null,
      sheets: [{ name: "SheetOne", data: [{ c: 2 }] }],
      collection: [{ a: 1, b: 2 }],
      sheetExample: {},
      fullList: [],
    };
  },
  components: {
    CustomTableTopFilter,
    DisplayImage,
    CustomTableBottomPagination,
    XlsxRead,
    XlsxSheets,
    XlsxJson,
    XlsxWorkbook,
    XlsxSheet,
    XlsxDownload,
  },
  props: {
    widgetClasses: String,
    tableName: String,
    list: Array,
    showStatus: {
      type: Boolean,
      default: true,
    },
    pageProp: Number,
    pages: Number,
    showFilters: {
      type: Boolean,
      default: true,
    },
    showDownloadBtn: {
      type: Boolean,
      default: true,
    },
    filterStatus: Array,
    loading: Boolean,
    itemsPerPage: Number,
    totalItems: Number,
  },
  computed: {},
  watch: {
    fullList: {
      handler: function (val) {
        this.createXLSX();
      },
      deep: true,
    },
  },
  emit: [
    "paginatorHandler",
    "updateStatus",
    "queryForProduct",
    "addProductToCart",
    "sortBy",
    "downloadPdf",
    "updateFetchPage",
  ],
  methods: {
    /*
     * ------------------EMITS------------------
     */
    addProduct(item) {
      if (!item.fltQuantidade) {
        return;
      }
      item.strCodArtigo = item.strCodigo;
      if (item.fltQuantidade < 1) item.fltQuantidade = 1;
      item = [item];
      this.$emit("addProductToCart", item);
    },
    updateFetchPage(page, pagesSize) {
      this.$emit("updateFetchPage", page, pagesSize, this.orderByAsc);
    },
    updateStatus(status) {
      this.$emit("updateStatus", status);
    },
    queryForProduct(data) {
      this.$emit("queryForProduct", data);
    },
    paginatorHandler(page) {
      this.$emit("paginatorHandler", page, this.orderAsc);
    },
    sortBy(orderBy) {
      this.showOrderByIcon = orderBy;
      this.$emit("sortBy", orderBy, !this.orderAsc);
      this.orderAsc = !this.orderAsc;
    },
    createXLSX() {
      this.sheetExample = [];
      let data = [];

      this.fullList.forEach((item) => {
        data.push({
          "Cód. Artigo": item.strCodigo,
          "Abreviatura": item.strAbreviatura,
          "Data de compra": this.getFormatedDate(item.dtmCompra),
          "Descrição": item.strDescricao,
          "Tipo Stock": item.tipoStock,
          Quantidade: "",
        });
      });

      //create one sheet
      this.sheetExample.push({
        name: "Encomendas",
        data: data,
      });

      this.isCreatingExcel = false;
    },
    getFormatedDate(date) {
      return new Date(date).toLocaleDateString("pt-pt").replaceAll("/", ".");
    },
    downloadPdf(document) {
      this.$emit("downloadPdf", document);
    },
  },
  mounted() {
    this.loadingExcel = true;
    ApiService.get(
      `/artigos/byclient?intCodEntidade=${store.getters.currentUser.numcl}&pageNumber=0&pageSize=0`
    )
      .then((response) => {
        this.fullList = response.data.Data;
        console.log("this.fullList: ", this.fullList)
        this.loadingExcel = false;
      })
      .catch((error) => {
        this.loadingExcel = false;
      });
  },
});
</script>

<style scoped>
.fixTableHead {
  overflow-y: auto;
}

.fixTableHead thead th {
  position: sticky;
  top: 10;
}

table {
  border-collapse: collapse;
  width: 100%;
}

tbody tr:nth-child(even) {
  background-color: rgba(255, 255, 255, 0.07);
}

tbody tr:nth-child(odd) {
  background-color: #ddf3ff;
}

.border-bot {
  border-bottom: 2px solid;
  border-bottom-color: white !important;
}

.border-solid-sub-table {
  border-bottom: 1px solid;
  border-bottom-color: black !important;
}

.border-dotted-sub-table {
  border-bottom: 1px dotted;
  border-bottom-color: grey !important;
}

.border-dotted-sub-table:last-child {
  border-bottom: none !important;
}

#table-head {
  background-color: #fff200 !important;
}

.white-text {
  color: white !important;
}

/* .btn-add-qtt {
  margin-left: 10px;
  aspect-ratio: 1/1 !important;
  height: 2.4rem !important;
  width: 2.4rem !important;
}

.btn-add-qtt:hover {
  get variable bg-primary
  background-color: #eef9ff !important;
} */
</style>
